import React from 'react';
import styled from 'styled-components';
import { borders, colors, mq } from '../../styles/theme';
import { isOdd } from '../../utils';

interface ITimeLineProps {
  startOnLeft?: boolean;
}

const TimeLine: React.FC<ITimeLineProps> = ({ children, startOnLeft }) => {
  return (
    <Timeline>
      {React.Children.map(children, (child, index) => (
        <>
          {(startOnLeft ? !isOdd(index) : isOdd(index)) && <Placeholder />}
          <TimeLineCard key={index}>
            <TimeLinePoint />
            <TimeLineCardContent>
              {React.isValidElement(child) && React.cloneElement(child)}
            </TimeLineCardContent>
          </TimeLineCard>
          {(startOnLeft ? isOdd(index) : !isOdd(index)) && <Placeholder />}
        </>
      ))}
    </Timeline>
  );
};

const Timeline = styled.div`
  display: grid;
  margin-top: 3rem;
  grid-template-columns: 1fr;
  ${mq.desktop} {
    grid-template-columns: repeat(1, 1fr 1fr);
  }
  & > div {
    padding: 1rem;
    ${mq.desktop} {
      padding: 0;
    }
    &:nth-of-type(odd) {
      padding-left: 1.5rem;
      border-left: 1px solid ${colors.passiveGrey};
      ${mq.desktop} {
        padding-left: 0;
        border-left: none;
        border-right: 1px solid ${colors.passiveGrey};
        padding-right: 1.5rem;
      }
    }
    &:nth-of-type(even) {
      padding-left: 1.5rem;
      border-left: 1px solid ${colors.passiveGrey};
    }
  }
`;

const Placeholder = styled.div`
  display: none;
  ${mq.desktop} {
    display: block;
  }
`;

const TimeLinePoint = styled.div`
  padding: 0;
  position: absolute;
  top: 1.75rem;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  background-color: ${colors.text.darkGrey};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  border: 3px solid white;
`;

const TimeLineCard = styled.div`
  position: relative;
  &:nth-of-type(odd) {
    ${TimeLinePoint} {
      left: -8px;
      ${mq.desktop} {
        left: initial;
        right: -8px;
      }
    }
  }
  &:nth-of-type(even) {
    ${TimeLinePoint} {
      left: -8px;
    }
  }
`;

const TimeLineCardContent = styled.div`
  padding: 1rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.09);
  ${borders.radius};
`;

TimeLine.defaultProps = {
  startOnLeft: true,
};

export default TimeLine;
