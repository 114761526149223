import React from 'react';
import styled from 'styled-components';
import { borders, colors, DottedBackground, fonts } from '../../styles/theme';

interface IDownloadMediaCTA {
  label: string;
  url: string;
}

const DownloadMediaCTA: React.FC<IDownloadMediaCTA> = ({ url, label }) => (
  <Container>
    <div>
      <a
        href={`https:${url}`}
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    </div>
  </Container>
);

const Container = styled.div`
  ${DottedBackground};
  padding: 7rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  a {
    ${borders.radius};
    background: ${colors.surface};
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    font-family: ${fonts.sansSerif};
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    text-align: center;
    transition: 400ms ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: #171717;
      color: ${colors.surface};
    }
  }
`;

export default DownloadMediaCTA;
