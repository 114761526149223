import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ExpertisesGrid from '../ExpertisesGrid';
import { BaseSpacer } from '../baseComponents';
import { AboutMeExpertisesFragment } from '../../../gatsby-graphql';
import { mq } from '../../styles/theme';

const AboutMeExpertises: React.FC<AboutMeExpertisesFragment['expertises']> = ({
  expertises,
  description,
}) => (
  <>
    <Container>
      <DescriptionContainer>
        {documentToReactComponents(description?.json, {})}
      </DescriptionContainer>
      <ExpertisesGrid expertises={expertises} />
    </Container>
    <StyledBaseSpacer />
  </>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  ${mq.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
`;

const DescriptionContainer = styled.div`
  align-self: center;
`;

const StyledBaseSpacer = styled(BaseSpacer)`
  padding-bottom: 4rem;
  margin-bottom: 4rem;
`;

export default AboutMeExpertises;
