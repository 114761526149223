import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BaseSpacer } from '../baseComponents';
import { AboutMeSillsFragment } from '../../../gatsby-graphql';
import { mq } from '../../styles/theme';
import SkillsList from './SkillsList';

const AboutMeSkills: React.FC<AboutMeSillsFragment['skills']> = ({
  skills,
  description,
}) => (
  <>
    <Container>
      <SkillsList skills={skills} />
      <DescriptionContainer>
        {documentToReactComponents(description?.json, {})}
      </DescriptionContainer>
    </Container>
    <StyledBaseSpacer />
  </>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  ${mq.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
`;

const DescriptionContainer = styled.div`
  align-self: center;
  order: -1;
  ${mq.desktop} {
    text-align: right;
    order: 0;
  }
`;

const StyledBaseSpacer = styled(BaseSpacer)`
  padding-bottom: 4rem;
  margin-bottom: 4rem;
`;

export default AboutMeSkills;
