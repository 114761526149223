import React from 'react';
import SVG from 'react-inlinesvg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import { borders, colors } from '../../styles/theme';
import { ExpertisesListFragment } from '../../../gatsby-graphql';

interface IExpertisesGridProps {
  expertises: ExpertisesListFragment['expertises'];
}

const ExpertisesGrid: React.FC<IExpertisesGridProps> = ({ expertises }) => (
  <ExpertisesContainer>
    <div>
      {expertises?.slice(0, 2).map((expertise) => (
        <Expertise key={expertise?.id as string}>
          <SVG src={expertise?.icon?.file?.url as string} />
          <h3>{expertise?.name}</h3>
          {documentToReactComponents(expertise?.description?.json, {})}
        </Expertise>
      ))}
    </div>
    <div>
      {expertises?.slice(2, 4).map((expertise) => (
        <Expertise key={expertise?.id as string}>
          <SVG src={expertise?.icon?.file?.url as string} />
          <h3>{expertise?.name}</h3>
          {documentToReactComponents(expertise?.description?.json, {})}
        </Expertise>
      ))}
    </div>
  </ExpertisesContainer>
);

const ExpertisesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  & > div {
    & > div:first-of-type {
      margin-bottom: 1.5rem;
    }
  }
  & > div:last-of-type {
    margin-top: 1.5rem;
  }
`;

const Expertise = styled.div`
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.09);
  ${borders.radius};
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1.5rem 1.25rem 2.5rem;
  &:nth-of-type(2) {
    grid-column-gap: 2rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0;
    color: ${colors.text.grey};
  }
  svg {
    height: 48px;
    margin-bottom: 1.5rem;
  }
`;

export default ExpertisesGrid;
