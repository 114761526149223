import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Date from '../Date';
import { AboutMeWorkAndEducationFragment } from '../../../gatsby-graphql';
import TimeLine from '../TimeLine';
import { colors, fonts, mq } from '../../styles/theme';

interface IAboutMeHistoryProps {
  locale: string;
  workAndEducation: AboutMeWorkAndEducationFragment['workAndEducation'];
}

const AboutMeHistory: React.FC<IAboutMeHistoryProps> = ({
  workAndEducation,
  locale,
}) => (
  <Container>
    <h2>{workAndEducation?.employmentHistoryTitle}</h2>
    <TimeLine>
      {workAndEducation?.employmentHistory?.map((experience) => (
        <TimeLineContent key={experience?.id}>
          <h3>{experience?.jobTitle}</h3>
          <h4>{experience?.employer}</h4>
          <span>
            <Date
              date={String(experience?.startDate)}
              format="MMMM, YYYY"
              locale={locale}
            />
          </span>
          <span className="spacer">-</span>
          <span>
            {!experience?.currentlyWorkHere ? (
              <Date
                date={String(experience?.endDate)}
                format="MMMM, YYYY"
                locale={locale}
              />
            ) : (
              'Present'
            )}
          </span>
          <div>
            {documentToReactComponents(experience?.description?.json, {})}
          </div>
        </TimeLineContent>
      ))}
    </TimeLine>
    <h2>{workAndEducation?.educationTitle}</h2>
    <TimeLine startOnLeft={false}>
      {[workAndEducation?.educationHistory].map((education) => (
        <TimeLineContent key={education?.id}>
          <h3>{education?.school}</h3>
          <h4>{education?.degree}</h4>
          <span>
            <Date
              date={String(education?.startDate)}
              format="MMMM, YYYY"
              locale={locale}
            />
          </span>
          <span className="spacer">-</span>
          <span>
            <Date
              date={String(education?.endDate)}
              format="MMMM, YYYY"
              locale={locale}
            />
          </span>
        </TimeLineContent>
      ))}
    </TimeLine>
  </Container>
);

const Container = styled.div`
  h2 {
    text-align: left;
    &:last-of-type {
      margin: 3rem 0 0;
    }
    ${mq.desktop} {
      text-align: center;
    }
  }
`;

const TimeLineContent = styled.div`
  h3 {
    margin-bottom: 0.5rem;
    font-size: 20px;
  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  span {
    text-transform: capitalize;
    font-family: ${fonts.sansSerif};
    color: ${colors.text.grey};
    font-size: 14px;
    &.spacer {
      margin: 0 0.125rem;
    }
  }
  div {
    font-family: ${fonts.serif};
    p {
      font-size: 16px;
      margin: 0.5rem 0 0;
    }
  }
`;

export default AboutMeHistory;
