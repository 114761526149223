import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { BaseContainer, BaseSpacer } from '../baseComponents';
import { AboutMeImagesFragment } from '../../../gatsby-graphql';
import { borders, fonts, mq, spacing } from '../../styles/theme';

interface IAboutMeInfoProps {
  copy: Document;
  images: AboutMeImagesFragment['aboutMeImages'];
}

const AboutMeInfo: React.FC<IAboutMeInfoProps> = ({ copy, images }) => (
  <BaseContainer>
    <Content>
      <ImageContainer>
        <ImageBackdrop />
        {images?.map((image, index) => (
          <StyledGatsbyImage
            key={index}
            loading="eager"
            fluid={image?.fluid as FluidObject}
            draggable={false}
          />
        ))}
      </ImageContainer>
      <CopyContainer>{documentToReactComponents(copy, {})}</CopyContainer>
    </Content>
    <StyledBaseSpacer />
  </BaseContainer>
);

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: ${spacing.sectionPageContent.top.base};
  column-gap: 70px;
  ${mq.desktop} {
    grid-template-columns: 1fr 2fr;
    padding-top: ${spacing.sectionPageContent.top.desktop};
  }
`;

const StyledBaseSpacer = styled(BaseSpacer)`
  padding-bottom: 4rem;
  margin-bottom: 4rem;
`;

const CopyContainer = styled.div`
  h2 {
    margin-bottom: 0.5rem;
  }
  p {
    font-family: ${fonts.serif};
    &:first-of-type {
      font-family: ${fonts.sansSerif};
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  align-self: flex-start;
  display: none;
  ${mq.desktop} {
    display: block;
  }
`;

const ImageBackdrop = styled.div`
  position: absolute;
  background-color: #171717;
  height: 100%;
  width: 100%;
  right: -25px;
  bottom: -25px;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  // ${borders.radius};
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export default AboutMeInfo;
