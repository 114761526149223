import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { IPage } from '../types';
import { AboutPageQuery } from '../../gatsby-graphql';
import PageBanner from '../components/PageBanner';
import AboutMeInfo from '../components/AboutMeInfo';
import { BaseContainer } from '../components/baseComponents';
import AboutMeExpertises from '../components/AboutMeExpertises';
import AboutMeSkills from '../components/AboutMeSkills';
import AboutMeHistory from '../components/AboutMeHistory';
import DownloadMediaCTA from '../components/DownloadMediaCTA';

const About: React.FC<IPage<AboutPageQuery>> = ({
  data: { footerSocialLinkLabel, navigationItem, siteNavigation, person },
  pageContext: { locale },
  location: { pathname },
}) => (
  <Layout
    withBottomSpacing={false}
    pagePathName={pathname}
    pageTitle={navigationItem?.name as string}
    pageDescription={navigationItem?.seo?.description as string}
    pageImage={navigationItem?.seo?.image?.file?.url as string}
    lang={locale}
    section={navigationItem}
    siteNavigation={siteNavigation}
    footerSocialLabel={footerSocialLinkLabel?.value as string}
  >
    <PageBanner copy={person?.bannerCopy?.json} />
    <AboutMeInfo
      copy={person?.aboutMeCopy?.json}
      images={person?.aboutMeImages}
    />
    <BaseContainer>
      <AboutMeExpertises {...person?.expertises} />
      <AboutMeSkills {...person?.skills} />
      <AboutMeHistory
        locale={locale}
        workAndEducation={person?.workAndEducation}
      />
    </BaseContainer>
    <DownloadMediaCTA
      label={person?.resumeCta?.label as string}
      url={person?.resumeCta?.media?.file?.url as string}
    />
  </Layout>
);

export default About;

export const pageQuery = graphql`
  query AboutPage($locale: String!, $sectionSlug: String!) {
    ...PageInfoFragment
    ...AboutPageInfo
    navigationItem: contentfulNavigationItem(
      slug: { eq: $sectionSlug }
      node_locale: { eq: $locale }
    ) {
      ...NavigationSectionInfo
    }
  }
`;
