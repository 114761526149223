import React from 'react';
import styled from 'styled-components';
import { borders, colors, fonts } from '../../styles/theme';
import { SkillsListFragment } from '../../../gatsby-graphql';

const SkillsList: React.FC<SkillsListFragment> = ({ skills }) => (
  <Container>
    {skills?.map((skill) => (
      <div key={skill?.name as string}>
        <p>{skill?.name}</p>
        <Bar>
          <div style={{ width: `${skill?.percentage}%` }} />
        </Bar>
      </div>
    ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  p {
    margin-bottom: 0.5rem;
    font-family: ${fonts.sansSerif};
    font-weight: 600;
  }
`;

const Bar = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 20px;
  background-color: ${colors.passiveGrey};
  ${borders.radius};
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    ${borders.radius};
    background-color: ${colors.text.grey};
  }
`;

export default SkillsList;
